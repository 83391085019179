const locationsReducer = (
  state = {
    loading: false,
    data: null,
    currentLocation: null,
    distance: null,
    nearLocation: null,
    selectedLocation: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_LOCATIONS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_USER_CURRENT_LOCATION":
      return {
        ...state,
        currentLocation: action.payload.data,
      };

    case "SET_USER_DISTANCE":
      return {
        ...state,
        distance: action.payload.distance,
      };

    case "SET_LOCATIONS_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "SET_SELECTED_LOCATION":
      return {
        ...state,
        selectedLocation: action.payload.data,
      };

    case "SET_NEAR_LOCATION":
      return {
        ...state,
        nearLocation: action.payload.data,
      };

    case "RESET":
      return state;

    default:
      return state;
  }
};
export default locationsReducer;
