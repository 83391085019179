import { all, put, takeEvery } from "redux-saga/effects";
import { setErrorStatus } from "../status/action";
import { compareFace } from "../../Services/api";
import store from "../store";
import { base64ToImageFile } from "../../Utils/constants";
import { isValidObject } from "../../Services/validators";

export const actionTypes = {
  CHECK_FACE_COMPARISON: "CHECK_FACE_COMPARISON",
  CLEAR_FACE_COMPARISON: "CLEAR_FACE_COMPARISON",
};

function* checkFaceComparisonWorker(action) {
  try {
    yield setFaceComparisonLoading(true);
    const accessToken = store.getState().auth.data.accessToken;
    const locations = store.getState().locations;
    const profileData = store.getState().profile.data;

    const nearLocationData = locations.nearLocation;
    if (isValidObject(nearLocationData)) {
      let data = new FormData();
      data.append(
        "photo",
        base64ToImageFile(action.payload.file, "image.png", "image/png")
      );
      data.append("PCTNo", nearLocationData?.PCTNo);
      data.append("ward", nearLocationData?.ward);
      data.append("address", nearLocationData.location);
      data.append("zone", nearLocationData?.zone);
      data.append("locationId", nearLocationData.documentId);
      data.append("employeeName", profileData?.name);
      data.append("employeeId", profileData?.employeeId);
      data.append("employeePhoneNumber", profileData?.phoneNumber);

      const response = yield compareFace(data, accessToken);

      yield put({
        type: "SET_FACE_COMPARISON_DATA",
        payload: {
          data: response.data,
        },
      });
    } else {
      setErrorStatus({ code: "custom", message: "Not in valid a location" });
    }

    yield setFaceComparisonLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setFaceComparisonLoading(false);
  }
}
function* clearFaceComparisonWorker() {
  try {
    yield setFaceComparisonLoading(true);

    yield put({
      type: "CLEAR_FACE_COMPARISON_DATA",
    });

    yield setFaceComparisonLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setFaceComparisonLoading(false);
  }
}

export default function* faceComparisonWatcher() {
  yield all([
    takeEvery("CHECK_FACE_COMPARISON", checkFaceComparisonWorker),
    takeEvery("CLEAR_FACE_COMPARISON", clearFaceComparisonWorker),
  ]);
}

function* setFaceComparisonLoading(bool) {
  yield put({
    type: "SET_FACE_COMPARISON_LOADING",
    payload: {
      loading: bool,
    },
  });
}
