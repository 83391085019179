const faceComparisonReducer = (
  state = {
    loading: false,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case "SET_FACE_COMPARISON_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_FACE_COMPARISON_DATA":
      return {
        ...state,
        data: action.payload.data,
      };

    case "CLEAR_FACE_COMPARISON_DATA":
      return {
        ...state,
        data: null,
      };

    case "RESET":
      return state;

    default:
      return state;
  }
};
export default faceComparisonReducer;
