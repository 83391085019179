import React, { useEffect, useState } from "react";
import * as faceapi from "@vladmandic/face-api";
import * as tf from "@tensorflow/tfjs";
import "./FaceDetection.css";

const FaceDetection = (props) => {
  const [image, setImage] = useState(null);
  const [detections, setDetections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFaceDetected, setIsFaceDetected] = useState(false);

  useEffect(() => {
    props.setIsFaceDetected(isFaceDetected);
    // eslint-disable-next-line
  }, [isFaceDetected]);

  useEffect(() => {
    props.setLoading(loading);
    // eslint-disable-next-line
  }, [loading]);

  const initializeFaceApi = async () => {
    if (!tf.getBackend()) {
      await tf.setBackend("wasm");
      await tf.ready();

      const modelPath = process.env.PUBLIC_URL + "/models";
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(modelPath),
        faceapi.nets.faceLandmark68Net.loadFromUri(modelPath),
        faceapi.nets.faceRecognitionNet.loadFromUri(modelPath),
      ]);
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) {
      return;
    }
    setLoading(true);

    const imageURL = file;
    setImage(imageURL);

    await initializeFaceApi();

    const img = document.getElementById("uploadedImage");
    const detections = await faceapi
      .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks();

    const isFaceDetected = detections.some(
      (detection) => detection.detection._score > 0.5
    );

    if (isFaceDetected) {
      console.log("Face(s) detected with good confidence.");
      setIsFaceDetected(true);
    } else {
      console.log("No face detected with high confidence.");
      setIsFaceDetected(false);
    }

    setDetections(detections);
    setLoading(false);
  };

  useEffect(() => {
    if (props.capturedImage) {
      handleImageUpload(props.capturedImage);
    }
    // eslint-disable-next-line
  }, [props.capturedImage]);

  return (
    <div className="inherit-parent-width height-80-percentage padding-large">
      {image && (
        <div className="inherit-parent-width inherit-parent-height display-flex position-relative">
          {loading && (
            <div className="overlay">
              <div className="primary-loading-animation" />
            </div>
          )}
          <img
            id="uploadedImage"
            src={image}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              border: `solid 2px ${isFaceDetected ? "green" : "red"}`,
            }}
            onLoad={(e) => {
              const img = e.target;
              const displayedWidth = img.offsetWidth;
              const displayedHeight = img.offsetHeight;
              console.log(
                `Original Dimensions: ${img.naturalWidth}x${img.naturalHeight}`
              );
              console.log(
                `Displayed Dimensions: ${displayedWidth}x${displayedHeight}`
              );
            }}
          />
          {detections.map((detection, index) => {
            const { x, y, width, height } = detection.detection.box;
            const img = document.getElementById("uploadedImage");
            const scaleX = img.offsetWidth / img.naturalWidth;
            const scaleY = img.offsetHeight / img.naturalHeight;
            const scaledBox = {
              x: x * scaleX,
              y: y * scaleY,
              width: width * scaleX,
              height: height * scaleY,
            };

            return (
              <div
                key={index}
                style={{
                  position: "absolute",
                  border: "2px solid green",
                  left: scaledBox.x,
                  top: scaledBox.y,
                  width: scaledBox.width,
                  height: scaledBox.height,
                  pointerEvents: "none",
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FaceDetection;
