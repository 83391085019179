import React from "react";
import "./TrackProgress.css";
import { Circle, CloseIcon, TickIcon } from "../../Assets/assets";

export default function TrackProgress(props) {
  return (
    <div className="inherit-parent-height">
      {props.data?.map((data, index) => (
        <div key={index}>
          <div className="display-flex ">
            <div>
              <Circle
                height="10"
                width="10"
                fill={data.status ? "#404040" : "none"}
                stroke="#404040"
                strokeOpacity={data.status ? "1" : "0.5"}
              />
            </div>
            <div
              className="display-flex inherit-parent-width flex-justify-content-space-between"
              style={{ width: "100%" }}
            >
              <div
                className="padding-left-large text-overflow-ellipsis"
                style={{ width: "250px" }}
              >
                {data.title}
              </div>
              {(index === 0 || props.data[index - 1].status) && (
                <div
                  className=" display-flex flex-align-items-center"
                  style={{ position: "relative", right: "0" }}
                >
                  <div
                    className="display-flex flex-align-items-center padding-small margin-right-default"
                    data-cy={
                      data.status
                        ? `${data.title}-completed`
                        : `${data.title}-complete-button`
                    }
                    onClick={() => {
                      if (props.loading !== true) {
                        data.isProofRequired
                          ? props.setSelectedTaskIndex(index, true, true)
                          : props.setSelectedTaskIndex(index, true, false);
                      }
                    }}
                  >
                    <TickIcon color={data.success && "#00a000"} />
                  </div>
                  <div
                    className="display-flex flex-align-items-center padding-small margin-left-default"
                    data-cy={
                      !data.status
                        ? `${data.title}-incomplete-button`
                        : `${data.title}-incomplete`
                    }
                    onClick={() => {
                      if (props.loading !== true) {
                        props.setSelectedTaskIndex(index, false, true);
                      }
                    }}
                  >
                    <CloseIcon color={data.success === false && "#E64539"} />
                  </div>
                </div>
              )}
            </div>
          </div>
          {data.description ? (
            <div className="display-flex">
              <div className="width-16px flex-align-items-center flex-justify-content-center">
                {!(index === props.data.length - 1) && (
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height ${
                      data.status ? "black-dashed-border" : "grey-dashed-border"
                    } `}
                  />
                )}
              </div>
              <div
                className=" padding-left-large padding-top-default"
                style={{ width: "calc(100% - 70px)" }}
              >
                <div className="font-size-small">{data.description}</div>
              </div>
            </div>
          ) : (
            !(index === props.data.length - 1) && (
              <div className="display-flex">
                <div className="width-16px height-32px flex-align-items-center flex-justify-content-center">
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height ${
                      data.status ? "black-dashed-border" : "grey-dashed-border"
                    }`}
                  />
                </div>
              </div>
            )
          )}
          {data.description && !(index === props.data.length - 1) && (
            <div className="display-flex">
              <div className="width-16px flex-align-items-center flex-justify-content-center">
                <div
                  style={{ width: "2px", height: "9px" }}
                  className={`${
                    data.status ? "black-dashed-border" : "grey-dashed-border"
                  }`}
                />
              </div>
              <div className="padding-left-large padding-top-large"></div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
