import { all } from "redux-saga/effects";
import authWatcher from "./authentication/saga";
import profileWatcher from "./profile/saga";
import statusWatcher from "./status/saga";
import complaintWatcher from "./complaints/saga";
import proceduresWatcher from "./procedures/saga";
import locationsWatcher from "./locations/saga";
import mockWatcher from "./mock/saga";
import faceComparisonWatcher from "./faceComparison/saga";

export default function* rootSaga() {
  yield all([
    authWatcher(),
    faceComparisonWatcher(),
    complaintWatcher(),
    locationsWatcher(),
    mockWatcher(),
    proceduresWatcher(),
    profileWatcher(),
    statusWatcher(),
  ]);
}
