import complaintImage from "../../Assets/complaintImage.svg";
import faceRecImage from "../../Assets/faceRecImage.svg";
import Header from "../../Components/Header/Header";
import procedureImage from "../../Assets/procedureImage.svg";
import trackImage from "../../Assets/trackImage.svg";
import "./Home.css";
import { rippleEffect } from "../../Utils/constants";
import Footer from "../../Components/Footer/Footer";

export default function Home(props) {
  return (
    <div className="inherit-parent-height inherit-parent-width">
      <Header
        title="GCC"
        profileOnClick={() => {
          props.navigate("/profile");
        }}
      />
      <div className="remaining-body-height flex-direction-column">
        <div
          style={{
            fontStyle: "bold",
          }}
          className="text-align-center padding-top-larger font-family-RHD-medium"
        >
          EMPLOYEE PORTAL
        </div>
        <div
          className="display-grid"
          style={{
            gridTemplateColumns: "auto auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.map((data, index) => (
            <div className="width-fit-content height-fit-content" key={index}>
              <CategoryCards
                data={data}
                index={index + 1}
                comingSoon={["TRACKING"].includes(data.title) ? true : false}
                onClick={(event) => {
                  rippleEffect(event);
                  if (!["TRACKING"].includes(data.title)) {
                    props.navigate(data.goTo);
                  }
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

const CategoryCards = (props) => {
  return (
    <>
      <div
        onClick={(e) => {
          props.onClick(e);
        }}
        data-cy={`${props.data.title}-card`}
        className="display-flex height-fit-content position-relative"
      >
        {props.comingSoon && (
          <div className="overlay font-size-medium">COMING SOON...</div>
        )}
        <div
          style={{
            padding: "15px",
          }}
          className={`width-fit-content height-fit-content`}
        >
          <div className="flex-align-items-center flex-justify-content-center flex-direction-column">
            <div
              style={{
                width: "156px",
                height: "170px",
              }}
            >
              <img
                loading="lazy"
                src={props.data.image}
                alt={props.data.title}
              />
            </div>
            <div className="font-size-medium">{props.data.title}</div>
            <div className="font-size-small padding-top-default">
              {props.data.description}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "2px",
            height: "248px",
          }}
          className={`${
            props.index % 2 === 1
              ? props.index === 1
                ? "vertical-bottom-border-gradient"
                : "vertical-top-border-gradient"
              : ""
          }`}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: "2px",
        }}
        className={`${
          props.index !== 3 && props.index !== 4
            ? props.index === 1
              ? "horizontal-right-border-gradient"
              : "horizontal-left-border-gradient"
            : ""
        }`}
      />
    </>
  );
};

const data = [
  {
    title: "COMPLAINT",
    description: "Manage assigned complaint",
    image: complaintImage,
    goTo: "/complaints",
  },
  {
    title: "PROCEDURE",
    description: "Manage assigned procedure",
    image: procedureImage,
    goTo: "/locationSearch",
  },
  {
    title: "TRACKING",
    description: "Track work by Start & Stop",
    image: trackImage,
    goTo: "/complaints",
  },
  {
    title: "FACE ID",
    description: "Face Id for attendance",
    image: faceRecImage,
    goTo: "/faceIdentification/locationSearch",
  },
];
