import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module FaceComparisonSaga
 */

/**
 *
 * @param {file} payload complains data
 */
export function checkFaceComparison(file) {
  store.dispatch({
    type: actionTypes.CHECK_FACE_COMPARISON,
    payload: {
      file: file,
    },
  });
}

export function clearFaceComparison() {
  store.dispatch({
    type: actionTypes.CLEAR_FACE_COMPARISON,
  });
}
