import React from "react";

export default function Footer() {
  return (
    <footer
      style={{
        height: "42px",
        bottom: "1px",
        left: "0px",
        right: "0px",
        backgroundColor: "rgba(242, 242, 242, 1)",
      }}
      className="inherit-parent-width position-absolute display-flex flex-justify-content-center flex-align-items-center"
    >
      <div className="font-size-smaller text-align-center">
        © 2024 Byepo Technologies Private Limited. All Rights Reserved.
      </div>
    </footer>
  );
}
